import { createContext, useContext, useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core';
import Web3 from 'web3';
import { mainnet } from './config';
import { WalletConnectConnector } from "@web3-react/walletconnect-connector"
import { InjectedConnector } from "@web3-react/injected-connector";
import { contracts } from './abi';

const injected = new InjectedConnector({
    supportedChainIds: [56, 97]
});

const walletconnect = new WalletConnectConnector({
    rpc: {
        97: mainnet.chain_info.rpcUrls[0]
    },
    chainId: 97,
    supportedChainIds: [97, 56]
})

const AuthContext = createContext({})

export const useAuth = () => useContext(AuthContext)

export const AuthContextProvider = ({children}) => {
    const web3 = useWeb3React()
    const [web3type, setWeb3type] = useState(null)
    const [loading, setLoading] = useState(false)

    const [tokenct, setTokenct] = useState(null)
    const [armysNFTct, setArmysNFTct] = useState(null)
    const [equipNFTct, setEquipNFTct] = useState(null)
    const [gameItemct, setGameItemct] = useState(null)
    const [gameContract, setGameContract] = useState(null)

    const [tokenAllow, setTokenAllow] = useState(false)
    const [armysAllow, setArmysAllow] = useState(false)
    const [equipAllow, setEquipAllow] = useState(false)
    const [itemsAllow, setItemsAllow] = useState(false)

    const [check1, setCheck1] = useState(false)

    useEffect(() => {
        setWeb3type(localStorage.getItem("web3type"))
    }, [])

    useEffect(() => {
        if (web3type === "mtm" && web3) {
            web3.activate(injected)
        }
        else if (web3type === "wc" && web3) {
            web3.activate(walletconnect)
        }
    }, [web3type, web3])

    useEffect(() => {
        if (web3.active) {
            localStorage.setItem("web3type", web3type)
        }
        if (web3 && web3.active && web3.library) {
            changeNetwork()
        }
        if (web3 && web3.active) {
            setTokenct(new web3.library.eth.Contract(contracts.token_wwf.abi, contracts.token_wwf.contract))
            setArmysNFTct(new web3.library.eth.Contract(contracts.army_nft.abi, contracts.army_nft.contract))
            setEquipNFTct(new web3.library.eth.Contract(contracts.equip_nft.abi, contracts.equip_nft.contract))
            setGameItemct(new web3.library.eth.Contract(contracts.game_items.abi, contracts.game_items.contract))
            setGameContract(new web3.library.eth.Contract(contracts.game.abi, contracts.game.contract))
        }
        // eslint-disable-next-line
    }, [web3])

    useEffect(() => {
        if (web3 && web3.active && tokenct && armysNFTct && equipNFTct && gameItemct) {
            getAllow()
        }
        // eslint-disable-next-line
    }, [web3, tokenct, armysNFTct, equipNFTct, gameItemct])

    const getAllow = async () => {
        setTokenAllow(parseFloat(web3.library.utils.fromWei(await tokenct.methods.allowance(web3.account, contracts.game.contract).call())) > parseFloat(web3.library.utils.fromWei(await tokenct.methods.totalSupply().call())))
        setArmysAllow(await armysNFTct.methods.isApprovedForAll(web3.account, contracts.game.contract).call())
        setEquipAllow(await equipNFTct.methods.isApprovedForAll(web3.account, contracts.game.contract).call())
        setItemsAllow(await gameItemct.methods.isApprovedForAll(web3.account, contracts.game.contract).call())
    }

    const changeNetwork = async () => {
        try {
            await web3.library._provider.request({
                method: "wallet_switchEthereumChain",
                params: [
                    {
                        chainId: mainnet.chain_info.chainId
                    }
                ]
            });
        } catch (switchError) {
            console.log(switchError)
            try {
                await web3.library._provider.request({
                    method: "wallet_addEthereumChain",
                    params: [
                        mainnet.chain_info
                    ]
                });
            } catch (error) {
                console.log(error);
            }
        }
    }

    const web3main = new Web3(new Web3.providers.HttpProvider( mainnet.chain_info.rpcUrls[0]));

    // useEffect(() => {
    //     if (armysNFTct && !check1) {
    //         setCheck1(true)
    //         grantMinterRole(armysNFTct)
    //     }
    // }, [armysNFTct])

    const grantMinterRole = async (contract) => {
        contract.methods.grantRole(
            await contract.methods.MINTER_ROLE().call(),
            contracts.game.contract
        ).send({
            from: web3.account,
            gasPrice: 10000000000,
            gas: await contract.methods.grantRole(
                await contract.methods.MINTER_ROLE().call(),
                contracts.game.contract
            ).estimateGas({ from: web3.account })
        })
    }

    return (
        <AuthContext.Provider value={{
            web3, web3main, setWeb3type,
            loading, setLoading, changeNetwork,
            tokenct, armysNFTct, equipNFTct, gameItemct, gameContract,
            tokenAllow, armysAllow, equipAllow, itemsAllow,
        }}>
            {children}
        </AuthContext.Provider>
    )
}