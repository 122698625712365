import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";

const Sidebar = () => {
  const [selectedPath, setSelectedPath] = useState("/home");
  const { web3, setWeb3type, loading, tokenct, gameItemct, gameContract } = useAuth();

  const [tokenBalance, setTokenbalance] = useState(0);
  const [medalBalance, setMedalbalance] = useState(0);
  const [shrapnelsBalance, setShrapnelsBalance] = useState(0);
  const [cartridgeBalance, setCartridgeBalance] = useState(0);

  const [ingameBalance, setIngameBalance] = useState(0);

  useEffect(() => {
    if (tokenct) {
      tokenct.methods
        .balanceOf(web3.account)
        .call()
        .then((res) => {
          setTokenbalance(Number(web3.library.utils.fromWei(res)));
        });
    }
    // eslint-disable-next-line
  }, [tokenct, loading]);

  useEffect(() => {
    if (gameItemct) {
      gameItemct.methods.balanceOf(web3.account, 2).call().then((res) => {
        setCartridgeBalance(Number(res));
      });
      gameItemct.methods.balanceOf(web3.account, 1).call().then((res) => {
        setShrapnelsBalance(Number(res));
      });
      gameItemct.methods.balanceOf(web3.account, 0).call().then((res) => {
        setMedalbalance(Number(res));
      });
    }
    // eslint-disable-next-line
  }, [gameItemct, loading]);

  useEffect(() => {
    if (gameContract) {
      gameContract.methods.balanceBNB(web3.account).call().then((res) => {
        setIngameBalance(Number(web3.library.utils.fromWei(res)));
      });

    }
    // eslint-disable-next-line
  }, [gameContract, loading]);

  const history = useLocation();

  useEffect(() => {
    setSelectedPath(history.pathname);
  }, [history]);

  const renderWeb3 = () => {
    return (
      <>
        <li key="tokenBalance" className="uk-nav">
          {/* eslint-disable-next-line */}
          <a style={{
            fontSize: "18px"
          }}>
            <img className="token_img" src="/assets/nft/items/token.png" alt="" />
            <span>{tokenBalance.toLocaleString('en-US')}</span>
          </a>
        </li>

        <li key="medalBalance" className="uk-nav">
          {/* eslint-disable-next-line */}
          <a style={{
            fontSize: "18px"
          }}>
            <img className="token_img" src="/assets/nft/items/medal.png" alt="" />
            <span>{medalBalance.toLocaleString('en-US')}</span>
          </a>
        </li>

        <li key="shrapnelsBalance" className="uk-nav">
          {/* eslint-disable-next-line */}
          <Link to={"/cartridge"} style={{
            fontSize: "18px"
          }}>
            <img className="token_img" src="/assets/nft/items/shrapnels.png" alt="" />
            <span>{shrapnelsBalance.toLocaleString('en-US')}</span>
          </Link>
        </li>

        <li key="cartridgeBalance" className="uk-nav">
          <Link to={"/cartridge"} style={{
            fontSize: "18px"
          }}>
            <img className="token_img" src="/assets/nft/items/cartridge.png" alt="" />
            <span>{cartridgeBalance.toLocaleString('en-US')}</span>
          </Link>
        </li>

        <li key="ingameBalance" className="uk-nav">
          <Link to={"/withdraw"} style={{
            fontSize: "18px"
          }}>
            <img className="token_img" src="https://bin.bnbstatic.com/static/images/common/favicon.ico" alt="" />
            <span>{ingameBalance.toLocaleString('en-US')}</span>
          </Link>
        </li>

        <li key="web3.account" className="uk-nav">
          {/* eslint-disable-next-line */}
          <a style={{
            fontSize: "18px"
          }}>
            <span>
              {web3.account.slice(0, 7)}...{web3.account.slice(web3.account.length - 7, web3.account.length)}
            </span>
          </a>
        </li>

        <li style={{ justifyContent: "center", display: "flex", marginTop: "10px" }}>
          <button
            className="uk-button uk-button-danger"
            onClick={() => {
              setWeb3type(null);
              localStorage.removeItem("web3type");
              web3.deactivate();
            }}
          >
            <i className="ico_arrow-circle-right"></i>
            <span className="uk-margin-small-left">Disconnect</span>
          </button>
        </li>
      </>
    );
  };

  const adminMenuItems = [
    {
      name: "home",
      label: "Home",
      to: "/",
    },
    {
      name: "mint",
      label: "Mint NFT",
      to: "/mint",
    },
    {
      name: "battle",
      label: "Battle Field",
      to: "/battle",
    },
    {
      name: "missions",
      label: "Missions",
      to: "/missions",
    },
    {
      name: "mynfts",
      label: "My NFTs",
      to: "/my-nft",
    },
    {
      name: "myequip",
      label: "My Equipments",
      to: "/my-equipment",
    },
    // {
    //   name: "nftDetail",
    //   iconClass: "ico_wallet",
    //   label: "NFT Detail",
    //   to: "/nftDetail",
    // },
    {
      name: "market",
      label: "Marketplace",
      to: "/marketplace",
    },

    // {
    //   name: "charts",
    //   iconClass: "ico_chats",
    //   label: "Charts",
    //   to: "/charts",
    // },

    // {
    //   name: "wallets",
    //   iconClass: "ico_wallet",
    //   label: "Wallets",
    //   to: "/wallets",
    // },
    // {
    //   name: "store",
    //   iconClass: "ico_store",
    //   label: "Store",
    //   to: "/games-store",
    // },
    // {
    //   name: "market",
    //   iconClass: "ico_market",
    //   label: "Market",
    //   to: "/market",
    // },
    // {
    //   name: "streams",
    //   iconClass: "ico_streams",
    //   label: "Streams",
    //   to: "/streams",
    // },
    // {
    //   name: "community",
    //   iconClass: "ico_community",
    //   label: "Community",
    //   to: "/community",
    // },
  ];

  function getMenuItems() {
    return adminMenuItems;
  }

  function isActive(path) {
    if (path === "/") {
      return selectedPath === path;
    }
    return selectedPath.indexOf(path) > -1;
  }

  function renderMenuItem(item) {
    const active = isActive(item.to);

    const classesItem = classNames("uk-nav", {
      "uk-active": active,
    });

    return (
      <li key={item.name} className={classesItem}>
        <Link to={item.to}>
          <img src={`/assets/icon/menu/${item.name}.png`} width="20" />
          <span>{item.label}</span>
        </Link>
      </li>
    );
  }

  function renderMenu() {
    const menuItems = getMenuItems();
    return menuItems.map((item, index) => {
      return renderMenuItem(item);
    });
  }

  return (
    <aside className="sidebar is-show" id="sidebar">
      <div className="sidebar-box">
        <ul className="uk-nav">
          <li className="uk-nav-header">Menu</li>
          {renderMenu()}
          <li key={`item.name`} className="uk-nav">
            <a href="https://forms.gle/LRGBtJRv21Kbxwwf7">
              <span>Feedback</span>
            </a>
          </li>

          <li className="uk-nav-header">Wallet</li>
          {web3 && web3.active && renderWeb3()}
        </ul>
      </div>
    </aside>
  );
};

export default Sidebar;
