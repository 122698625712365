import { useAuth } from "../../context/AuthContext";
import { mainnet } from "../../context/config";
import ConnectWallet from "../wallet/connectwallet";
import Sidebar from "./sidebar";
import Header from "./header";
import { Backdrop, CircularProgress } from "@mui/material";
import AllowWallet from "../wallet/allowwallet";

const Layout = ({ children }) => {
  const {
    web3, setWeb3type, loading, setLoading,
    tokenAllow, armysAllow, equipAllow, itemsAllow,
    tokenct, armysNFTct, equipNFTct, gameItemct, gameContract
  } = useAuth();
  return (
    <div className="page-wrapper">
      <>
        <Header web3={web3} setWeb3type={setWeb3type} />
        <div className="page-content">
          <Sidebar />
          {web3 && web3.active && web3.chainId === mainnet.chain_num ? (
            tokenAllow && armysAllow && equipAllow && itemsAllow ?
              children
              : <AllowWallet
                web3={web3} setLoading={setLoading}
                tokenAllow={tokenAllow} armysAllow={armysAllow} equipAllow={equipAllow} itemsAllow={itemsAllow}
                tokenct={tokenct} armysNFTct={armysNFTct} equipNFTct={equipNFTct} gameItemct={gameItemct} gameContract={gameContract}
              />
          ) : (
            <ConnectWallet />
          )}
          {/* {children} */}
          {/* <ConnectWallet /> */}
        </div>
      </>
      <Backdrop open={loading} style={{
        zIndex: 9999
      }}>
        <div className="flex justify-center m-auto flex-col">
          <p className="text-white font-bold w-2/3 m-auto text-center">
            Loading
          </p>
          <CircularProgress className="m-auto text-white" color="inherit" />
        </div>
      </Backdrop>
    </div>
  );
};

export default Layout;
