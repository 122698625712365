import { Web3ReactProvider } from "@web3-react/core";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Web3 from "web3";
import Layout from "./components/layout/layout";
import { AuthContextProvider } from "./context/AuthContext";
import ChartsPage from "./pages/charts";
import CommunityPage from "./pages/community";
import FavouritesPage from "./pages/favourites";
import GamesStorePage from "./pages/games-store";
import HomePage from "./pages/home";
import MarketPage from "./pages/market";
import MarketPlacePage from "./pages/marketplace";
import MissionPage from "./pages/mission";
import MyEquip from "./pages/myequip";
import MyNftPage from "./pages/myNft";
import NftDetail from "./pages/nftDetail";
import OpenCartridge from "./pages/nftDetail/cartridge";
import StreamsPage from "./pages/streams";
import WalletsPape from "./pages/wallets";

function getLibrary(provider) {
  return new Web3(provider);
}

function App() {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <AuthContextProvider>
        <BrowserRouter>
          <Layout>
            <Routes>
              <Route path="/">
                <Route path="*" element={<HomePage />} />
                <Route index element={<HomePage />} />
                <Route path="/mint" element={<MarketPage />} />
                <Route path="/my-nft" element={<MyNftPage />} />
                <Route path="/my-equipment" element={<MyEquip />} />
                <Route path="/cartridge" element={<OpenCartridge />} />
                <Route path="/battle" element={<MissionPage />} />
                <Route path="/missions" element={<CommunityPage />} />

                {/* <Route path="/favourites" element={<FavouritesPage />} /> */}
                {/* <Route path="/charts" element={<ChartsPage />} /> */}
                {/* <Route path="/friends" element={<FriendsPage />} /> */}
                {/* <Route path="/wallets" element={<WalletsPape />} /> */}
                {/* <Route path="/games-store" element={<GamesStorePage />} /> */}
                {/* <Route path="/streams" element={<StreamsPage />} /> */}
                {/* <Route path="/nftDetail" element={<NftDetail />} /> */}
                {/* <Route path="/marketplace" element={<MarketPlacePage />} /> */}
              </Route>
            </Routes>
          </Layout>
          <ToastContainer
            className="p-4"
            position="top-right"
            autoClose={4000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </BrowserRouter>
      </AuthContextProvider>
    </Web3ReactProvider>
  );
}

export default App;
