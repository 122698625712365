import "./index.css";

const Header = () => {

  return (
    <header className="page-header">
      <div className="page-header__inner">
        <div className="page-header__sidebar">
          <div className="page-header__menu-btn">
            <button className="menu-btn ico_menu is-active"></button>
          </div>
          <div className="page-header__logo">
            <img src="/assets/img/logo.png" alt="logo" />
            {/* <span className="page-header__logo_text">TEAMHOST</span> */}
          </div>
        </div>
        <div className="page-header__content">
          {/* <div className="page-header__action d-none d-lg-flex">
            {web3 && web3.active && (
              <>
                <div className="total__amount account_container search__input token_container">
                  <img className="token_img" src="/assets/img/token.png" />
                  <span>{tokenBalance}</span>
                </div>

                <div className="total__amount account_container search__input">
                  <img className="token_img" src="/assets/img/shrapnel.png" />
                  <span>{shrapnelsBalance}</span>
                </div>
                <div className="total__amount account_container search__input">
                  <img className="token_img" src="/assets/img/cartridge.png" />
                  <span>{cartridgeBalance}</span>
                </div>
                <span className="total__amount account_container search__input">
                  {web3.account.slice(0, 7)}...
                  {web3.account.slice(
                    web3.account.length - 7,
                    web3.account.length
                  )}
                </span>
                <button className="uk-button uk-button-danger" onClick={() => {
                  setWeb3type(null);
                  localStorage.removeItem("web3type");
                  web3.deactivate();
                }}>
                  <i className="ico_arrow-circle-right"></i><span className="uk-margin-small-left">Disconnect</span>
                </button>
              </>
            )}
          </div> */}
          {/* <div className="page-header__menu-btn d-none d-sm-flex">
            <button className="menu-btn ico_menu is-active"></button>
          </div> */}
        </div>
      </div>
    </header>
  );
};

export default Header;
