import { toast } from "react-toastify";
import { contracts } from "../../context/abi";
import "./connectwallet.css";

const AllowWallet = ({ web3, setLoading, tokenAllow, armysAllow, equipAllow, itemsAllow, tokenct, armysNFTct, equipNFTct, gameItemct, gameContract }) => {

  const approveToken = async () => {
    setLoading(true)
    tokenct.methods.approve(contracts.game.contract,web3.library.utils.toWei((parseFloat(web3.library.utils.fromWei(await tokenct.methods.totalSupply().call()))*100).toString(), "ether"))
      .send({
        from: web3.account,
        gasPrice: 10000000000,
        gas: await tokenct.methods.approve(contracts.game.contract,web3.library.utils.toWei((parseFloat(web3.library.utils.fromWei(await tokenct.methods.totalSupply().call()))*100).toString(), "ether")).estimateGas({ from: web3.account }),
      })
      .then(() => {
        toast("Approved");
        setLoading(false);
      })
      .catch(() => {
        toast("Approve failed");
        setLoading(false);
      });
  }

  const approveNFT = async (contract) => {
    setLoading(true)
    contract.methods.setApprovalForAll( contracts.game.contract, true)
      .send({
        from: web3.account,
        gasPrice: 10000000000,
        gas: await contract.methods.setApprovalForAll(contracts.game.contract, true).estimateGas({ from: web3.account })
      })
      .then(() => {
        toast("Approved");
        setLoading(false);
      })
      .catch(() => {
        toast("Approve failed");
        setLoading(false);
      });
  }

  return (
    <main className="page-main">
      <div className="account-form-area">
        <h3 className="title">Approve Settings</h3>
        <div className="account-form-wrapper">
          <div className="form-group text-center mt-2">
            <button className={tokenAllow ? "cmn-btn2 m-1" : "cmn-btn m-1"} disabled={tokenAllow} onClick={approveToken}>
              {tokenAllow ? "Approved" : "Approve"} $TOKEN
            </button>

            <button className={armysAllow ? "cmn-btn2 m-1" : "cmn-btn m-1"} disabled={armysAllow} onClick={() => approveNFT(armysNFTct)}>
              {armysAllow ? "Approved" : "Approve"} Army NFT
            </button>
          </div>
          <div className="form-group text-center mt-2">
            <button className={equipAllow ? "cmn-btn2 m-1" : "cmn-btn m-1"} disabled={equipAllow} onClick={() => approveNFT(equipNFTct)}>
              {equipAllow ? "Approved" : "Approve"} Equip NFT
            </button>

            <button className={itemsAllow ? "cmn-btn2 m-1" : "cmn-btn m-1"} disabled={itemsAllow} onClick={() => approveNFT(gameItemct)}>
              {itemsAllow ? "Approved" : "Approve"} Items
            </button>
          </div>

        </div>
      </div>
    </main>
  );
};

export default AllowWallet;
