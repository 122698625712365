import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import "./myNft.css";

export default function MyNftPage() {
  const { web3, armysNFTct } = useAuth()
  const [armys, setArmys] = useState([])

  useEffect(() => {
    if (web3 && web3.active) {
      initArmys()
    }
    // eslint-disable-next-line
  }, [armysNFTct, web3])

  const initArmys = async () => {
    const nfts = await armysNFTct.methods.nftTemplateOfOwner(web3.account).call()
    setArmys(nfts.map(val => val))
  }

  return (
    <main className="page-main">
      <div className="uk-grid">
        <div className="uk-width-1-1@l">
          <div className="widjet --activity">
            <div className="widjet__head">
              <h3 className="uk-text-lead">My NFTs</h3>
            </div>
            <div className="uk-grid">
              {
                armys.sort((a, b) => Number(b.blood) - Number(a.blood))
                  .map(
                    (val) => (<div key={`army-${val.tokenid}`} className="uk-width-1-3@l uk-width-1-2@m mb-4">
                      <div className="user-item --active">
                        <div className="user-item-avt">
                          <img src={`/assets/nft/army/${val.tier}.png`} width={100} alt="" />
                        </div>
                        <div className="user-item__box">
                          <div className="user-item__name"><span>#{val.tokenid} {val.tier === "1" ? "SOLDIER" : val.tier === "2" ? "SWAT" : val.tier === "3" ? "TANK" : val.tier === "4" ? "AIRCRAFT" : ""}</span></div>
                          <div className="user-item__playing">Blood: <b>{val.blood}</b></div>
                        </div>
                      </div>
                    </div>)
                  )
              }

            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
