import { useEffect, useState } from "react";

export default function Card({ web3, image, title, nfttype, initPrice, mintNft, mintStatus }) {

  const [bnbPrice, setBNBPrice] = useState(null)
  const [tokenPrice, setTokenPrice] = useState(null)
  const [quantity, setQuantity] = useState(1)
  const [paytype, setPaytype] = useState(0)
  const [maxMint, setMaxMint] = useState(0)
  const [countMinted, setCountMinted] = useState(0)

  useEffect(() => {
    if (web3 && web3.active) {
      initPrice2()
    }
    // eslint-disable-next-line
  }, [web3, paytype, quantity])

  const initPrice2 = async () => {
    const prices = await initPrice(quantity, paytype, nfttype)
    setBNBPrice(prices.bnb_)
    setTokenPrice(prices.token_)
    const status = await mintStatus(nfttype)
    setCountMinted(Number(status[0]))
    setMaxMint(Number(status[1]))
  }

  return (
    <div className="widjet__body">
      <div className="widjet-game">
        <div className="widjet-game__media">
          <span>
            <img src={image} alt="" />
          </span>
        </div>
        <div className="widjet-game__info">
          <div className="widjet-game__title">
            <span>{title}</span>
          </div>
          {/* <div className="widjet-game__game-name">{gameName}</div> */}
          <div className="widjet-game__quantity">
            Quantity:{" "}
            <input
              type="number"
              name="quant[1]"
              className="form-control input-number quantity-input"
              placeholder="0"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              min="1"
              max="4"
            />
          </div>
          <div className="widjet-game__starting">
            BNB:<b>{web3.library.utils.fromWei(bnbPrice || "0")}</b>

          </div>
          <div className="widjet-game__quantity">
            WW4:<b>{web3.library.utils.fromWei(tokenPrice || "0")}</b>
          </div>
          <div className="widjet-game__quantity">
            Remain: {countMinted} / {maxMint}
          </div>
        </div>
      </div>
      <div className="widjet-right">
        <select className="select-btn" defaultValue={0} value={paytype} onChange={(e) => setPaytype(e.target.value)}>
          <option value={0}>BNB</option>
          <option value={1}>TOKEN</option>
        </select>
        <button className="creat-list-btn" onClick={() => mintNft(quantity, paytype, nfttype)} disabled={countMinted >= maxMint}>
          <i className="ico_add-square"></i>
          <span>Mint NFT</span>
        </button>
      </div>
    </div>
  );
}
