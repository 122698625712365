import React from "react";

const NftDetail = () => {
  return (
    <>
        <main className="page-main">
          <ul className="uk-breadcrumb">
            <li>
              <a href="09_games-store.html">
                <span data-uk-icon="chevron-left"></span>
                <span>Back to Store</span>
              </a>
            </li>
            <li>
              <span></span>
            </li>
          </ul>
          <h3 className="uk-text-lead"></h3>
          <div className="uk-grid uk-grid-small" data-uk-grid>
            <div className="uk-width-2-3@s">
              <div className="gallery">
                <div className="js-gallery-big gallery-big">
                  <div className="swiper">
                    <div className="swiper-wrapper">
                      <div className="swiper-slide">
                        <img src="/assets/img/c0.jpg" alt=""></img>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="uk-width-1-3@s">
              <div className="game-profile-card">
                <div className="game-profile-card__media">
                  <img
                    src="/assets/img/game-profile-card.jpg"
                    alt="game-profile-card"
                  ></img>
                </div>
                <div className="game-profile-card__intro">
                  <span>
                    TeamHost is a simulation and strategy game of managing a
                    city struggling to survive after apocalyptic global cooling.
                  </span>
                </div>
                <ul className="game-profile-card__list">
                  <li>
                    <div>Reviews:</div>
                    <div className="game-card__rating">
                      <span>4.7</span>
                      <i className="ico_star"></i>
                      <span className="rating-vote">(433)</span>
                    </div>
                  </li>
                  <li>
                    <div>Release date:</div>
                    <div>24 Apr, 2018</div>
                  </li>
                  <li>
                    <div>Developer:</div>
                    <div>11 bit studios</div>
                  </li>
                  <li>
                    <div>Platforms:</div>
                    <div className="game-card__platform">
                      <i className="ico_windows"></i>
                      <i className="ico_apple"></i>
                    </div>
                  </li>
                </ul>
                <ul className="game-profile-card__type">
                  <li>
                    <span>Strategy</span>
                  </li>
                  <li>
                    <span>Survival</span>
                  </li>
                  <li>
                    <span>City Builder</span>
                  </li>
                  <li>
                    <span>Dark</span>
                  </li>
                </ul>
              </div>
              <div className="game-profile-price">
                <div className="game-profile-price__value">$13.99 USD</div>
                <button
                  className="uk-button uk-button-danger uk-width-1-1"
                  type="button"
                >
                  <span className="ico_shopping-cart"></span>
                  <span>Buy Now</span>
                </button>
                <button
                  className="uk-button uk-button-primary uk-width-1-1"
                  type="button"
                >
                  <span className="ico_add-square"></span>
                  <span>Add to Favourites</span>
                </button>
              </div>
            </div>
          </div>
        </main>
    </>
  );
};

export default NftDetail;
