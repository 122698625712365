import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ratingStars } from "../../context/abi";
import { useAuth } from "../../context/AuthContext";

export default function HomePage() {
  const { web3, loading, equipNFTct, gameContract } = useAuth()

  const [stats, setStats] = useState(null)
  const [equips, setEquips] = useState([])

  useEffect(() => {
    if (equipNFTct && gameContract && web3 && web3.active) {
      initAccount()
    }
    // eslint-disable-next-line
  }, [equipNFTct, gameContract, loading])

  const initAccount = async () => {
    setStats(await gameContract.methods.calcFightPower(web3.account).call())
    setEquips([
      await equipNFTct.methods.equipDetail(await gameContract.methods.allEquiped(web3.account, 1).call()).call(),
      await equipNFTct.methods.equipDetail(await gameContract.methods.allEquiped(web3.account, 2).call()).call(),
      await equipNFTct.methods.equipDetail(await gameContract.methods.allEquiped(web3.account, 3).call()).call(),
      await equipNFTct.methods.equipDetail(await gameContract.methods.allEquiped(web3.account, 4).call()).call(),
    ])
  }

  return (
    <React.Fragment>
      <main className="page-main">
        <div className="uk-grid" data-uk-grid>
          <div className="uk-width-3-3@xl">
            <div className="widjet --filters">
              <div className="widjet__head">
                <h3 className="uk-text-lead">Home</h3>
              </div>
              <div className="widjet --profile">
                <div className="widjet__body">
                  <div className="user-info">
                    <div className="user-info__avatar">
                      <img src="/logo192.png" alt="profile" />
                    </div>
                    <div className="user-info__box">
                      <div className="user-info__title text-truncate">
                        {web3.account.slice(0, 7)}...{web3.account.slice(web3.account.length - 7, web3.account.length)}
                      </div>
                      <div className="user-info__text">
                        Fight Power: {stats && Number(stats.fightpower).toLocaleString('en-US')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-width-2-3@xl">
            <div className="widjet__head">
              <h3 className="uk-text-lead">Equipments</h3>
            </div>
            <div className="uk-grid uk-grid-small uk-child-width-1-1@m" data-uk-grid>
              {
                equips.filter((value) => Number(value.tokenid) > 0).map((val) => (
                  <div key={`equip-${val.tokenid}`}>
                    <div className="user-item">
                      <div className="user-avt">
                        <img src={`/assets/nft/equip/${val._type}/${val.rare}.jpg`} width={100} alt="" />
                      </div>
                      <div className="user-item__box">
                        <div className="user-item__name">
                          <span>#{val.tokenid} {val._type === "1" ? "Firearm" : val._type === "2" ? "Head" : val._type === "3" ? "Body" : val._type === "4" ? "Gear" : ""} {ratingStars(Number(val.rare))}</span>
                        </div>
                        <div className="uk-grid">
                          <div className="uk-width-1-3@l">
                            Level: <b>{val.level} ({val.exp}/{parseInt(Math.pow(1.1, Number(val.level) + 2) * Number(val.level) * 100)})</b>
                          </div>
                          <div className="uk-width-1-3@l">
                            Attack: <b>{val.attack}</b>
                          </div>
                          <div className="uk-width-1-3@l">
                            B.C: <b>{val.bulletcap}</b>
                          </div>
                          <div className="uk-width-1-3@l">
                            Defend: <b>{val.defend}</b>
                          </div>
                          <div className="uk-width-1-3@l">
                            Speed: <b>{val.speed}</b>
                          </div>
                          <div className="uk-width-1-3@l">
                            Luck: <b>{val.luck}</b>
                          </div>

                        </div>
                      </div>

                    </div>
                  </div>
                ))
              }
              {
                // eslint-disable-next-line
                equips.filter((value) => value._type != 0).length === 0 && <>
                  <Link to="/mint">
                    <div>
                      <div className="user-item">
                        <div className="user-avt">
                          <img src="/assets/nft/items/cartridge.png" width={100} alt="cartridge" />
                        </div>
                        <div className="user-item__box">
                          <div className="user-item__name">
                            <b>Cartridge</b>
                          </div>
                          <div className="user-item__playing">
                            Open Cartridges to get more Equipments
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Link to="/my-equipment">
                    <div>
                      <div className="user-item">
                        <div className="user-avt">
                          <img src="/assets/icon/defend.png" width={100} alt="cartridge" />
                        </div>
                        <div className="user-item__box">
                          <div className="user-item__name">
                            <b>My Equipments</b>
                          </div>
                          <div className="user-item__playing">
                            Equip your weapon
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </>
              }
            </div>
          </div>
          <div className="uk-width-1-3@xl">
            <div className="widjet --friend-requests">
              <div className="widjet__head">
                <h3 className="uk-text-lead">Stats</h3>
              </div>
              <div className="widjet__body">
                <ul className="friend-requests-list">
                  <li className="friend-requests-item">
                    <div className="friend-requests-item__avatar">
                      <img src="/assets/icon/attack.png" alt="user" />
                    </div>
                    <div className="friend-requests-item__name">
                      Attack: {stats && stats.attack}
                    </div>
                  </li>
                  <li className="friend-requests-item">
                    <div className="friend-requests-item__avatar">
                      <img src="/assets/icon/bc.png" alt="user" />
                    </div>
                    <div className="friend-requests-item__name">
                      Bullet Cap: {stats && stats.bulletcap}
                    </div>
                  </li>
                  <li className="friend-requests-item">
                    <div className="friend-requests-item__avatar">
                      <img src="/assets/icon/defend.png" alt="user" />
                    </div>
                    <div className="friend-requests-item__name">
                      Defend: {stats && stats.defend}
                    </div>
                  </li>
                  <li className="friend-requests-item">
                    <div className="friend-requests-item__avatar">
                      <img src="/assets/icon/speed.png" alt="user" />
                    </div>
                    <div className="friend-requests-item__name">
                      Speed: {stats && stats.speed}
                    </div>
                  </li>
                  <li className="friend-requests-item">
                    <div className="friend-requests-item__avatar">
                      <img src="/assets/icon/luck.png" alt="user" />
                    </div>
                    <div className="friend-requests-item__name">
                      Luck: {stats && stats.luck}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}
