import { useEffect, useState } from "react";
import Countdown from "react-countdown";
import { toast } from "react-toastify";

export default function CartridgeCard({ web3, image, title, gameContract, setLoading }) {
  const [bnbPrice, setBNBPrice] = useState(null)

  useEffect(() => {
    initPrice()
    // eslint-disable-next-line
  }, [web3])

  const initPrice = async () => {
    setBNBPrice(await gameContract.methods.anchor_originPrice().call())
  }

  const tradeCartrigde = async () => {
    await initPrice()
    setLoading(true)
    try {
      gameContract.methods.tradeCartrigde().send({
        from: web3.account,
        gasPrice: 10000000000,
        gas: await gameContract.methods.tradeCartrigde().estimateGas({ from: web3.account })
      }).then(() => {
        toast("Trade successfull");
        setLoading(false);
      }).catch(() => {
        toast("Trade failed");
        setLoading(false);
      });
    } catch (error) {
      toast("Trade failed");
      setLoading(false);
    }

  }

  return (
    <div className="widjet__body">
      <div className="widjet-game">
        <div className="widjet-game__media">
          <span>
            <img src={image} alt="" />
          </span>
        </div>
        <div className="widjet-game__info">
          <div className="widjet-game__title">
            <span>{title}</span>
          </div>
          <div className="widjet-game__starting">
            BNB in-game:<b>{web3.library.utils.fromWei(bnbPrice || "0")}</b>
          </div>
        </div>
      </div>
      <div className="widjet-right">
        <button className="creat-list-btn btn-sell" onClick={() => tradeCartrigde()}>
          <i className="ico_store"></i>
          <span>SELL</span>
        </button>
      </div>
    </div>
  );
}
