import { useEffect, useState } from "react";
import Countdown from "react-countdown";
import { toast } from "react-toastify";
import { useAuth } from "../../context/AuthContext";

export default function CommunityPage() {
  const { web3, loading, gameContract, setLoading } = useAuth()
  const [battles, setBattes] = useState([])

  useEffect(() => {
    if (web3 && web3.active) {
      initBattle()
    }
    // eslint-disable-next-line
  }, [web3, loading, gameContract])

  const initBattle = async () => {
    const get_battles = await gameContract.methods.getBattleOwner(web3.account).call()

    setBattes(get_battles.map(val => val))
  }

  const finishBattle = async (battleid) => {
    try {
      
      setLoading(true)
      gameContract.methods.finishBattle(Number(battleid)).send({
        from: web3.account,
        gasPrice: 10000000000,
        gas: Number(await gameContract.methods.finishBattle(Number(battleid)).estimateGas({ from: web3.account })) + 50000
      }).then((res) => {
        console.log(res)
        toast("Claim successful")
        setLoading(false)
      }).catch((res) => {
        console.log(res)
        toast("Claim failed")
        setLoading(false)
      })
    } catch (error) {
      toast("Claim failed")
      setLoading(false)
    }

  }

  return (
    <main className="page-main">
      <div className="uk-grid" data-uk-grid>
        <div className="uk-width-1-1@l">
          <div className="widjet --filters">
            <div className="widjet__head">
              <h3 className="uk-text-lead">Missions</h3>
            </div>
          </div>
          {
            battles.sort((a, b) => Number(b.claimat) - Number(a.claimat)).map((val) => (
              <div className="game-card --horizontal --small" key={`battle-${val.battleid}`}>
                <div className="game-card__box">
                  <div className="game-card__media">
                    <span>
                      <img src="/assets/map/fight.gif" alt="Map" />
                    </span>
                  </div>
                  <div className="game-card__info">
                  {/* eslint-disable-next-line */}
                    <a className="game-card__title" >
                      #{val.battleid}. {val[5].name}
                    </a>
                    <div className="game-card__discussion">
                      <Countdown
                        key={val.claimat + Date.now()}
                        date={Number(val.claimat) * 1000}
                        renderer={({ hours, minutes, seconds, completed }) => {
                          if (completed) {
                            // eslint-disable-next-line
                            return val.isclaimed ? `Reward: ${val[5].rewardType == "0" ? val.reward : web3.library.utils.fromWei(val.reward)} ${val[5].rewardType == "0" ? "Shrapnels" : "BNB"}` : "Available to claim"
                          } else {
                            return `Can be claimed in ${hours > 0 ? `${hours} hours,` : ""} ${minutes > 0 ? `${minutes} minutes,` : ""} ${seconds}s.`
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="game-card__more">
                    <button disabled={val.isclaimed} onClick={() => finishBattle(Number(val.battleid))} className="uk-button uk-button-danger">Claim Now</button>
                  </div>
                </div>
              </div>
            ))
          }


        </div>
      </div>
    </main>
  );
}
