import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useAuth } from "../../context/AuthContext";

const OpenCartridge = () => {
  const { web3, setLoading, gameItemct, gameContract } = useAuth()

  const [opentype, setOpenType] = useState(3)
  const [result, setResult] = useState(0)

  const [shrapnelAmount, setShrapnelAmount] = useState(0)
  const [shrapnelsBalance, setShrapnelsBalance] = useState(0)

  useEffect(() => {
    if (gameItemct) {
      
      gameItemct.methods.balanceOf(web3.account, 1).call().then((res) => {
        setShrapnelsBalance(Number(res));
      });
    }
  }, [gameItemct])


  const openBox = async () => {
    const balance = await gameItemct.methods.balanceOf(web3.account, 0).call()
    setResult(0)
    if (balance > 0) {
      setLoading(true)
      gameContract.methods.openCartridge(1, opentype).send({
        from: web3.account,
        gasPrice: 10000000000,
        gas: await gameContract.methods.openCartridge(1, opentype).estimateGas({ from: web3.account })
      }).on('receipt', function (receipt) {
        toast("Open successful")
        setResult(Number(receipt.events.ResultCartridge.returnValues._result))
        setLoading(false)
      }).on('error', function () {
          toast("Open failed")
          setLoading(false)
        })
    } else {
      toast("Not enough cartridge")
    }
  }

  const combineCartridge = async () => {
    
    if (shrapnelAmount <= shrapnelsBalance) {
      setLoading(true)
      gameContract.methods.fusionCartridge(shrapnelAmount).send({
        from: web3.account,
        gasPrice: 10000000000,
        gas: await gameContract.methods.fusionCartridge(shrapnelAmount).estimateGas({ from: web3.account })
      }).on('receipt', function (receipt) {
        toast("Combine successful")
        setLoading(false)
      }).on('error', function () {
          toast("Combine failed")
          setLoading(false)
        })
    } else {
      toast("Combine failed")
    }
  }

  return (
    <main className="page-main">
      <h3 className="uk-text-lead">Cartridge</h3>
      <div className="uk-grid uk-grid-small" data-uk-grid>
        <div className="uk-width-2-3@l">
          <div className="gallery">
            <img className="m-auto" src="/assets/nft/items/cartridge.png" alt=""></img>
          </div>
        </div>
        <div className="uk-width-1-3@l">
          <div className="game-profile-price">
            <div className="game-profile-card__intro">
              <h3>Pick package</h3>
              <select className="select-btn" defaultValue={0} value={opentype} onChange={(e) => setOpenType(Number(e.target.value))}>
                <option value={3}>MEDAL</option>
                <option value={1}>ARMY NFT</option>
                <option value={2}>EQUIPMENT NFT</option>
              </select>
            </div>
            <button className="uk-button uk-button-danger uk-width-1-1" onClick={openBox} >
              <span className="ico_shopping-cart"></span>
              <span>Open</span>
            </button>
          </div>
          <div className="game-profile-card">
            <div className="game-profile-card__intro">
              <h3>Result</h3>
            </div>
            <ul className="game-profile-card__list">
              <li>
                <div>Item:</div>
                <div className="game-card__rating">
                  <span>{opentype === 3 ? "MEDAL" : opentype === 1 ? "ARMY NFT" : opentype === 2 ? "EQUIPMENT NFT" : ""}</span>
                </div>
              </li>
              {result > 0 && <li>
                <div>{opentype === 3 ? "Quality:" : "TOKEN ID:"}</div>
                <div>{result}</div>
              </li>}
            </ul>
          </div>

          <div className="game-profile-price">
            <div className="game-profile-card__intro">
              <h3>Combine Shrapnels</h3>
              <p>(1000 shrapnels = 1 cartridge)</p>
              <input min={0} step={1000} max={shrapnelsBalance} type="number" className="input-btn" placeholder="Input shrapnels amount" value={shrapnelAmount} onChange={(e) => setShrapnelAmount(Number(e.target.value))} />
            </div>
            <button className="uk-button uk-button-danger uk-width-1-1" disabled={shrapnelsBalance <= 0} onClick={combineCartridge}>
              <span className="ico_windows"></span>
              <span>Combine</span>
            </button>
          </div>
        </div>
      </div>
    </main>
  );
};

export default OpenCartridge;
