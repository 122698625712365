import { useAuth } from "../../context/AuthContext";
import "./connectwallet.css";

const ConnectWallet = () => {
  const { setWeb3type } = useAuth();

  return (
    <main className="page-main">
        <div className="account-form-area">
          <h3 className="title">Connect Wallet</h3>
          <div className="account-form-wrapper">
            <div className="form-group text-center mt-2">
              <button
                className="cmn-btn m-1"
                onClick={() => {
                  setWeb3type("mtm");
                }}
              >
                <img src="/assets/logo/metamask.svg" width={165} alt="" />
              </button>

              <button
                className="cmn-btn m-1"
                onClick={() => {
                  setWeb3type("wc");
                }}
              >
                <img
                  src="https://walletconnect.com/images/logo.svg"
                  width={200} alt=""
                />
              </button>
            </div>

          </div>
        </div>
    </main>
  );
};

export default ConnectWallet;
