import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ratingStars } from "../../context/abi";
import { useAuth } from "../../context/AuthContext";
import "./myNft.css";

export default function MyEquip() {
  const { web3, equipNFTct, gameContract, setLoading, gameItemct, loading } = useAuth()
  const [equips, setEquips] = useState([])
  const [showtype, setShowtype] = useState(0)

  const [medalBalance, setMedalbalance] = useState(0);

  const [type1, setType1] = useState(null)
  const [type2, setType2] = useState(null)
  const [type3, setType3] = useState(null)
  const [type4, setType4] = useState(null)

  const [medals, setMedals] = useState({})

  useEffect(() => {
    if (web3 && web3.active) {
      gameContract.methods.allEquiped(web3.account, 1).call().then(async (res) => {
        if (Number(res) > 0) {
          setType1(await equipNFTct.methods.equipDetail(res).call())
        }
      }).catch(console.log)
      gameContract.methods.allEquiped(web3.account, 2).call().then(async (res) => {
        if (Number(res) > 0) {
          setType2(await equipNFTct.methods.equipDetail(res).call())
        }
      }).catch(console.log)

      gameContract.methods.allEquiped(web3.account, 3).call().then(async (res) => {
        if (Number(res) > 0) {
          setType3(await equipNFTct.methods.equipDetail(res).call())
        }
      }).catch(console.log)

      gameContract.methods.allEquiped(web3.account, 4).call().then(async (res) => {
        if (Number(res) > 0) {
          setType4(await equipNFTct.methods.equipDetail(res).call())
        }
      }).catch(console.log)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (web3 && web3.active) {
      initEquips()
      gameItemct.methods.balanceOf(web3.account, 0).call().then((res) => {
        setMedalbalance(Number(res));
      });
    }
    // eslint-disable-next-line
  }, [loading, web3])

  const initEquips = async () => {
    const nfts = await equipNFTct.methods.equipTemplateOfOwner(web3.account).call()
    setEquips(nfts.map(val => val))
  }

  const select = async (tokenid) => {
    const selected = equips.find(val => Number(val.tokenid) === Number(tokenid))
    if (selected) {
      switch (selected._type) {
        case "1":
          setType1(selected)
          break;
        case "2":
          setType2(selected)
          break;
        case "3":
          setType3(selected)
          break;
        case "4":
          setType4(selected)
          break;
        default:
          break;
      }
    }
  }

  const updateEquip = async () => {
    setLoading(true)
    let equipids = [0, 0, 0, 0]
    if (type1) {
      equipids[0] = Number(type1.tokenid)
    }
    if (type2) {
      equipids[1] = Number(type2.tokenid)
    }
    if (type3) {
      equipids[2] = Number(type3.tokenid)
    }
    if (type4) {
      equipids[3] = Number(type4.tokenid)
    }
    try {
      const gasp = await gameContract.methods.updateEquip(equipids).estimateGas({ from: web3.account })
      gameContract.methods.updateEquip(equipids).send({
        from: web3.account,
        gasPrice: 10000000000,
        gas: gasp
      }).then(() => {
        toast("Update successfull")
        setLoading(false)
      }).catch(() => {
        toast("Update failed")
        setLoading(false)
      })
    } catch (error) {
      toast("Update failed")
      setLoading(false)
    }
  }

  const upgradeEquip = async (tokenid) => {
    try {
      setLoading(true)
      gameContract.methods.upgradeEquip(Number(medals[tokenid] || 1), Number(tokenid)).send({
        from: web3.account,
        gasPrice: 10000000000,
        gas: await gameContract.methods.upgradeEquip(Number(medals[tokenid] || 1), Number(tokenid)).estimateGas({ from: web3.account })
      }).then(() => {
        toast("Update successfull")
        setLoading(false)
      }).catch(() => {
        toast("Update failed")
        setLoading(false)
      })
    } catch (error) {
      toast("Upgrade failed")
      setLoading(false)
    }
  }

  return (
    <main className="page-main">
      <div className="uk-grid">
        <div className="uk-width-1-1@l">
          <div className="widjet --activity">
            <div className="widjet__head uk-grid">
              <div className="uk-width-1-2@l">
                <h3 className="uk-text-lead">Equipments</h3>
              </div>
              <div className="uk-width-1-2@l">
                <select className="select-btn" defaultValue={0} value={showtype} onChange={(e) => setShowtype(Number(e.target.value))}>
                  <option value={0}>All</option>
                  <option value={1}>Firearm</option>
                  <option value={2}>Head</option>
                  <option value={3}>Body</option>
                  <option value={4}>Gear</option>
                </select>
              </div>
            </div>
            <div className="mb-4">
              <div className="widjet --games">
                <div className="">
                  <div className="user-item row">
                    <div className="d-flex flex-column col-lg-3 col-md-6">
                      <span className="text-center">Firearm</span>
                      <span className="text-center">#{type1?.tokenid}</span>
                      <img onClick={() => setType1(null)} className="m-auto" src={type1 ? `/assets/nft/equip/1/${type1.rare}.jpg` : `/assets/nft/equip/0.png`} width={100} alt="" />
                    </div>
                    <div className="d-flex flex-column col-lg-3 col-md-6">
                      <span className="text-center">Head</span>
                      <span className="text-center">#{type2?.tokenid} </span>
                      <img onClick={() => setType2(null)} className="m-auto" src={type2 ? `/assets/nft/equip/2/${type2.rare}.jpg` : `/assets/nft/equip/0.png`} width={100} alt="" />
                    </div>
                    <div className="d-flex flex-column col-lg-3 col-md-6">
                      <span className="text-center">Body</span>
                      <span className="text-center">#{type3?.tokenid}</span>
                      <img onClick={() => setType3(null)} className="m-auto" src={type3 ? `/assets/nft/equip/3/${type3.rare}.jpg` : `/assets/nft/equip/0.png`} width={100} alt="" />
                    </div>
                    <div className="d-flex flex-column col-lg-3 col-md-6">
                      <span className="text-center">Gear</span>
                      <span className="text-center">#{type4?.tokenid}</span>
                      <img onClick={() => setType4(null)} className="m-auto" src={type4 ? `/assets/nft/equip/4/${type4.rare}.jpg` : `/assets/nft/equip/0.png`} width={100} alt="" />
                    </div>
                    <div className="d-flex flex-column col-12 mt-4">
                      <button className="creat-list-btn btn-buy m-auto" onClick={updateEquip}>
                        <span>EQUIP</span>
                      </button>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="uk-grid">
              {
                equips.filter(val => showtype === 0 ? Number(val._type) !== "0" : Number(val._type) === showtype)
                  .sort((a, b) => Number(b.rare) - Number(a.rare)).sort((a, b) => Number(b.exp) - Number(a.exp))
                  .map(
                    (val) => (<div key={`army-${val.tokenid}`} className="uk-width-1-2@m mb-4" onClick={() => select(val.tokenid)}>
                      <div className="user-item">
                        <div className="user-item-avt">
                          <img src={`/assets/nft/equip/${val._type}/${val.rare}.jpg`} width={100} alt="" />
                        </div>
                        <div className="user-item__box">
                          <div className="user-item__name">
                            <span>#{val.tokenid} {val._type === "1" ? "Firearm" : val._type === "2" ? "Head" : val._type === "3" ? "Body" : val._type === "4" ? "Gear" : ""} {ratingStars(Number(val.rare))}</span>
                          </div>
                          <div className="uk-grid">
                            <div className="uk-width-1-3@l">
                              Level: <b>{val.level} ({val.exp}/{parseInt(Math.pow(1.1, Number(val.level) + 2) * Number(val.level) * 100)})</b>
                            </div>
                            <div className="uk-width-1-3@l">
                              Attack: <b>{val.attack}</b>
                            </div>
                            <div className="uk-width-1-3@l">
                              Bullet Capacity: <b>{val.bulletcap}</b>
                            </div>
                            <div className="uk-width-1-3@l">
                              Defend: <b>{val.defend}</b>
                            </div>
                            <div className="uk-width-1-3@l">
                              Speed: <b>{val.speed}</b>
                            </div>
                            <div className="uk-width-1-3@l">
                              Luck: <b>{val.luck}</b>
                            </div>
                            <div className="uk-width-1-2@l mt-2">
                              <div className="search input-class">
                                <div className="search__input">
                                  <img width={30} src="/assets/nft/items/medal.png" alt="" />
                                  <input type="number" min={1} step={1} max={medalBalance} value={medals[val.tokenid] || 1} onChange={
                                    (e) => setMedals(old => ({
                                      ...old,
                                      [val.tokenid]: e.target.value
                                    }))
                                  } />
                                </div>
                              </div>
                            </div>
                            <div className="uk-width-1-2@l mt-2">
                              <div className="search">
                                <div className="search__input">
                                  <button className="creat-list-btn mt-0" onClick={() => upgradeEquip(val.tokenid)}>
                                    <span>UPGRADE</span>
                                  </button>
                                </div>
                              </div>

                            </div>
                          </div>


                        </div>
                      </div>
                    </div>)
                  )
              }

            </div>
          </div>
        </div>
      </div>

    </main>
  );
}
